import React from "react";
import CircularProgress from '@material-ui/core/CircularProgress';

import ParentEdit from './ParentEdit';
import ChildrenTable from "./ChildrenTable";
import NewChildModal from './NewChildModal';

import './actions.css';

class ActionEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            eventId: props.match.params.eid,
            parentId: props.match.params.pid,
            currentAction: null,
            types: []
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        this.fetchData(this.state.eventId, this.state.parentId);
    }

    openModal() {
        this.setState({
            open: true
        });
    }

    closeModal() {
        this.setState({
            open: false
        });
    }

    fetchData(eventId, parentId) {
        //Get current action
        fetch(global.baseAPIUrl + '/actions/' + eventId + '/' + parentId)
            .then((response) => {
                return response.json();
            })
            .then((action) => {

                // Get all types
                fetch(global.baseAPIUrl + '/actions/types', {
                    method: 'GET'
                })
                    .then(function (res) {
                        return res.json();
                    })
                    .then(function (types) {
                        if (types) {
                            action.Types = types;

                            this.setState({
                                currentAction: action,
                                loading: false
                            });
                        }
                    }.bind(this));
            });
    }

    showChildrenTable() {
        this.setState({
            childOverride: true
        });
    }

    render() {
        var newModal = (this.state.open)
            ? <NewChildModal
                open={this.state.open}
                parentId={this.state.parentId}
                types={this.state.currentAction.Types}
                close={this.closeModal}
                eventId={this.state.eventId}/>
            : <div/>;


        if (!this.state.loading && this.state.currentAction) {
            if (this.state.currentAction.Id) {
                return (
                    <div className='container' style={{marginTop: "40px"}}>
                        {newModal}
                        <ParentEdit action={this.state.currentAction} override={this.showChildrenTable.bind(this)} toggle={this.openModal}/>
                    </div>
                );
            } else {
                return (
                    <div className='container'>
                        {newModal}
                        <ChildrenTable parentId={this.props.match.params.pid} children={this.state.currentAction.Children} toggle={this.openModal}/>
                    </div>
                );
            }
        } else {
            return (
                <div className='container text-center' style={{marginTop: "200px"}}>
                    <CircularProgress size={100}/>
                </div>);
        }
    }
}

export default ActionEditor;