import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import {Paper, Form, Submit} from './DemoModule';
import {Modal} from "react-bootstrap";

class NewChildModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: true,
            loading: true,
            currentEventId: props.eventId,
            currentParentId: props.parentId,
            types: props.types,
            originalType: "",
            currentTypeId: 1,
            currentType: "",
            currentImage: "",
            currentTitle: "",
            currentValues: ""
        };

        this.saveChanges = this.saveChanges.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
    }

    closeModal() {
        if (typeof this.props.close === 'function') {
            this.props.close();
        }
    }

    handleTypeChange(event) {
        this.setState({
            currentTypeId: event.target.value
        });
    }

    handleImageChange(event) {
        this.setState({
            currentImage: event.target.value,
        });
    }

    handleTitleChange(event) {
        this.setState({
            currentTitle: event.target.value,
        });
    }

    handleValueChange(event) {
        var values = (this.state.currentValues) ? JSON.parse(this.state.currentValues) : {};
        values[event.target.id] = event.target.value;

        this.setState({
            currentValues: JSON.stringify(values)
        });
    }

    saveChanges(e) {
        var values = (this.state.currentValues) ? JSON.parse(this.state.currentValues) : {};

        var body = {
            parentId: this.state.currentParentId,
            type: this.state.currentTypeId,
            title: this.state.currentTitle,
            image: this.state.currentImage,
            value: JSON.stringify(values)
        };

        fetch(global.baseAPIUrl + '/actions/' + this.state.currentEventId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                window.location = window.location;
            });
    }

    render() {
        var fields = <div/>;

        const self = this;
        this.state.types.forEach(function (type) {
            if (type.Id === self.state.currentTypeId) {
                fields = type.Fields;
                return;
            }
        });

        return (
            <Modal show={this.state.open} onHide={this.closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add new Child for {this.state.parentId}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <form className={Form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={8}>
                                <TextField
                                    name="title"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="title"
                                    label="Title"
                                    autoFocus
                                    onChange={this.handleTitleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl
                                    style={{width: "100%"}}>
                                    <InputLabel>Type</InputLabel>
                                    <Select value={this.state.currentTypeId}
                                            onChange={this.handleTypeChange}>
                                        {
                                            this.state.types.map(type =>
                                                <MenuItem value={type.Id} key={type.Id}>
                                                    {type.Type}
                                                </MenuItem>)
                                        }
                                    </Select>
                                    <FormHelperText>{this.state.actionName}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={10}>
                                <TextField
                                    name="title"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="image"
                                    label="Image"
                                    onChange={this.handleImageChange}
                                    autoFocus
                                />
                            </Grid>

                            <Grid item xs={12} sm={2} className="text-center">
                                <img style={{width: "60px", height: "60px"}} src={this.state.currentImage}/>
                            </Grid>

                            {/* map type possibilities here */}

                            <Grid item xs={12} sm={12}>
                                {
                                    (fields && fields.length > 0)
                                        ? fields.map(field =>
                                            <TextField
                                                className="mb-4"
                                                variant="outlined"
                                                key={field.KeyVal}
                                                required
                                                fullWidth
                                                id={field.KeyVal}
                                                label={field.KeyVal}
                                                name={field.KeyVal}
                                                onChange={this.handleValueChange}/>
                                        )
                                        : <div/>
                                }
                            </Grid>
                        </Grid>
                    </form>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={this.saveChanges}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default NewChildModal;