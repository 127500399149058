import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import ActionEditor from './components/actions/ActionEditor';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';

import {Breadcrumb} from 'react-bootstrap';

export default function App() {
    return (
        <Router>
            <div>
                <nav>
                    <div style={{padding: "15px", background: "#183f83"}}>
                        <a href="/a/1/0">
                            <img src="/mcul.png" style={{width: "115px", height: "45px"}}/>
                        </a>
                    </div>
                </nav>

                <Switch>
                    <Route path="/a/:eid/:pid" component={ActionEditor}/>
                </Switch>
            </div>
        </Router>
    );
}

function Events() {
    return <h2>Events</h2>;
}