import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PeopleTable from './PeopleTable';

class PeopleWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            parentId: props.parentId,
            people: props.people,
            typeId: props.typeId,
            parent: props.parent,
            children: props.children,
            currentImage: null,
            currentFName: null,
            currentLName: null,
            currentPosition: null,
            currentEmail: null,
            currentPhone: null,
            currentDescription: null
        };

        this.fetchData = this.fetchData.bind(this);

        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleFNameChange = this.handleFNameChange.bind(this);
        this.handleLNameChange = this.handleLNameChange.bind(this);
        this.handlePositionChange = this.handlePositionChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    }

    componentDidMount() {
        this.fetchData(this.state.parentId);
    }

    fetchData(parentId) {
        //Get current action
        fetch(global.baseAPIUrl + '/people/' + parentId)
            .then((response) => {
                return response.json();
            })
            .then((people) => {
                this.setState({
                    people: people
                });
            })
    }

    createPerson() {
        var body = {
            image: this.state.currentImage,
            fName: this.state.currentFName,
            lName: this.state.currentLName,
            position: this.state.currentPosition,
            email: this.state.currentEmail,
            phone: this.state.currentPhone,
            description: this.state.currentDescription
        };

        //Get current action
        fetch(global.baseAPIUrl + '/people/' + this.state.parentId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                return response.json();
            })
            .then((people) => {
                window.location = window.location;
            });
    }

    handleImageChange(event) {
        this.setState({
            currentImage: event.target.value,
        });
    }

    handleFNameChange(event) {
        this.setState({
            currentFName: event.target.value,
        });
    }

    handleLNameChange(event) {
        this.setState({
            currentLName: event.target.value,
        });
    }

    handlePositionChange(event) {
        this.setState({
            currentPosition: event.target.value,
        });
    }

    handleEmailChange(event) {
        this.setState({
            currentEmail: event.target.value,
        });
    }

    handlePhoneChange(event) {
        this.setState({
            currentPhone: event.target.value,
        });
    }

    handleDescriptionChange(event) {
        this.setState({
            currentDescription: event.target.value,
        });
    }

    render() {
        var peopleTable = (this.state.people && this.state.people.length > 0) ?
            <PeopleTable people={this.state.people}/> : <div/>;

        return (
            <div>
                <div>
                    <h4 className="text-secondary mb-3 mt-4">People</h4>
                </div>
                <Grid container spacing={2} className="mb-4 pb-4 pt-4 jumbotron">
                    <Grid item xs={12} sm={2} className="text-center">
                        <img style={{width: "120px", height: "120px"}} src={this.state.currentImage}/>
                    </Grid>

                    <Grid item xs={12} sm={10} className="mt-4">
                        <TextField
                            name="image"
                            variant="outlined"
                            required
                            fullWidth
                            id="image"
                            label="Image"
                            defaultValue={this.state.currentImage}
                            onChange={this.handleImageChange}
                            autoFocus
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="fname"
                            variant="outlined"
                            required
                            fullWidth
                            id="fname"
                            label="First Name"
                            autoFocus
                            onChange={this.handleFNameChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} className="mb-2">
                        <TextField
                            id="lname"
                            variant="outlined"
                            required
                            fullWidth
                            label="Last Name"
                            autoFocus
                            onChange={this.handleLNameChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} className="mb-2">
                        <TextField
                            name="position"
                            variant="outlined"
                            required
                            fullWidth
                            id="position"
                            label="Position"
                            autoFocus
                            onChange={this.handlePositionChange}/>
                    </Grid>

                    <Grid item xs={12} sm={12} className="mb-2">
                        <TextField
                            name="description"
                            variant="outlined"
                            required
                            fullWidth
                            id="description"
                            label="Description"
                            autoFocus
                            onChange={this.handleDescriptionChange}/>
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <TextField
                            name="email"
                            variant="outlined"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            autoFocus
                            onChange={this.handleEmailChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <TextField
                            name="phone"
                            variant="outlined"
                            required
                            fullWidth
                            id="phone"
                            label="Phone"
                            autoFocus
                            onChange={this.handlePhoneChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={2} className="text-center">
                        <Button onClick={(e) => {
                            e.stopPropagation();
                            this.createPerson();
                        }} className="bg-primary text-light" style={{height: "100%"}}>
                            <i className="fa fa-plus"></i>
                        </Button>
                    </Grid>
                </Grid>

                {peopleTable}
            </div>
        );
    }
}

export default PeopleWrapper;