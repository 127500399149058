import React from 'react';
import Button from '@material-ui/core/Button';
import {ButtonGroup, Table, Modal} from "react-bootstrap";
import Grid from "@material-ui/core/Grid";

class UploadTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            documents: props.documents,
            parent: props.parent,
            children: props.children,
            currentTitle: null,
            currentStart: null,
            currentEnd: null,
            currentAddressTitle: null,
            currentAddress: null,
            currentDescription: null
        };
    }

    deletePerson(uploadId) {
        fetch(global.baseAPIUrl + '/documents/' + uploadId, {
            method: 'DELETE'
        })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                window.location = window.location;
            });
    }

    render() {
        if (!this.state.documents) {
            this.state.documents = [];
        }

        return (
            <div>
                <h4>Documents</h4>

                <Grid container spacing={2} className="mb-4 pb-4 pt-4 jumbotron">
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Size</th>
                            <th></th>
                        </tr>
                        </thead>

                        <tbody>
                        {
                            this.state.documents.map((child) =>
                                <tr key={child.Id}>
                                    <td>{child.Name}</td>
                                    <td>{child.Type}</td>
                                    <td>{child.Size / 1000} kb</td>

                                    <td style={{width: "4%", padding: "15px"}}>
                                        <ButtonGroup>
                                            <Button onClick={(e) => {
                                                e.stopPropagation();
                                                this.deletePerson(child.Id);
                                            }} className="btn-danger square-sm">
                                                <i className="fa fa-trash"></i>
                                            </Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </Table>
                </Grid>
            </div>
        );
    }
}

export default UploadTable;