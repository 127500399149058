import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Paper, Form, Submit} from './DemoModule';
import {Modal} from "react-bootstrap";

class UpdatePersonModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentPersonId: this.props.child.Id,
            currentImage: this.props.child.Image,
            currentFName: this.props.child.FName,
            currentLName: this.props.child.LName,
            currentPosition: this.props.child.Position,
            currentEmail: this.props.child.Email,
            currentPhone: this.props.child.Phone,
            currentDescription: this.props.child.Description

        };

        this.saveChanges = this.saveChanges.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleFNameChange = this.handleFNameChange.bind(this);
        this.handleLNameChange = this.handleLNameChange.bind(this);
        this.handlePositionChange = this.handlePositionChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    }

    closeModal() {
        if (typeof this.props.close === 'function') {
            this.props.close();
        }
    }

    handleImageChange(event) {
        this.setState({
            currentImage: event.target.value
        });
    }

    handleFNameChange(event) {
        this.setState({
            currentFName: event.target.value
        });
    }

    handleLNameChange(event) {
        this.setState({
            currentLName: event.target.value
        });
    }

    handlePositionChange(event) {
        this.setState({
            currentPosition: event.target.value
        });
    }

    handleEmailChange(event) {
        this.setState({
            currentEmail: event.target.value
        });
    }

    handlePhoneChange(event) {
        this.setState({
            currentPhone: event.target.value
        });
    }

    handleDescriptionChange(event) {
        this.setState({
            currentDescription: event.target.value
        });
    }

    saveChanges(e) {
        var body = {
            image: this.state.currentImage,
            fname: this.state.currentFName,
            lname: this.state.currentLName,
            position: this.state.currentPosition,
            email: this.state.currentEmail,
            phone: this.state.currentPhone,
            desc: this.state.currentDescription
        };

        fetch(global.baseAPIUrl + '/people/' + this.state.currentPersonId, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                window.location = window.location;
            });
    }

    render() {
        return (
            <Modal show={true} onHide={this.closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Person</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <form className={Form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    name="image"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="image"
                                    label="Image"
                                    onChange={this.handleImageChange}
                                    value={this.state.currentImage}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    name="fname"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="fname"
                                    label="First Name"
                                    onChange={this.handleFNameChange}
                                    value={this.state.currentFName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    name="lname"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="lname"
                                    label="Last Name"
                                    onChange={this.handleLNameChange}
                                    value={this.state.currentLName}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    name="position"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="position"
                                    label="Position"
                                    onChange={this.handlePositionChange}
                                    value={this.state.currentPosition}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    name="email"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    onChange={this.handleEmailChange}
                                    value={this.state.currentEmail}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    name="phone"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="phone"
                                    label="Phone"
                                    onChange={this.handlePhoneChange}
                                    value={this.state.currentPhone}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    name="description"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="description"
                                    label="Description"
                                    multiline
                                    onChange={this.handleDescriptionChange}
                                    value={this.state.currentDescription}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={this.saveChanges}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default UpdatePersonModal;