import styled from '@emotion/styled';

export const Paper = styled.div`
	    marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
        `;

export const Form = styled.div`
	    width: '100%',
        marginTop: theme.spacing(3)
        `;