import React from 'react';
import Button from '@material-ui/core/Button';
import {ButtonGroup, Table, Modal} from "react-bootstrap";

class ChildrenTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            parentId: props.parentId,
            parent: props.parent,
            children: props.children,
            open: false
        }
    }

    toggleParentModal(e) {
        if (typeof this.props.toggle === 'function') {
            this.props.toggle();
        }
    }

    handleChildRowClick(child) {
        window.location = '/a/' + child.EventId + '/' + child.Id;
    }

    deleteAction(actionId) {
        fetch(global.baseAPIUrl + '/actions/' + actionId, {
            method: 'DELETE'
        })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                window.location = window.location;
            });
    }

    render() {
        if (!this.state.children) {
            this.state.children = [];
        }

        return (
            <div>
                <div>
                    <h4 className="text-secondary mb-3 mt-4">Children</h4>
                </div>

                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Type</th>
                        <th>
                            <Button onClick={(e) => {
                                e.stopPropagation();
                                this.toggleParentModal(e);
                            }} className="btn-light float-right mr-4">
                                <i className="fa fa-plus"></i>
                            </Button>
                        </th>
                    </tr>
                    </thead>

                    <tbody>
                    {
                        this.state.children.map((child) =>
                            <tr key={child.Id}
                                onClick={this.handleChildRowClick.bind(this, child)}>
                                <td style={{width: "6.5%", padding: "0px"}}>
                                    <img className="center-cropped" src={child.Image}/>
                                </td>

                                <td>{child.Title}</td>
                                <td>{child.Type}</td>
                                <td style={{width: "4%", padding: "15px"}}>
                                    <ButtonGroup>
                                        <Button onClick={(e) => {
                                            e.stopPropagation();
                                            this.deleteAction(child.Id);
                                        }} className="btn-danger square-sm">
                                            <i className="fa fa-trash"></i>
                                        </Button>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default ChildrenTable;