import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import LocationTable from './LocationsTable';

class PeopleWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            parentId: props.parentId,
            locations: props.locations,
            typeId: props.typeId,
            parent: props.parent,
            children: props.children,
            currentName: null,
            currentAddress: null,
            currentDescription: null
        };

        this.fetchData = this.fetchData.bind(this);

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    }

    componentDidMount() {
        this.fetchData(this.state.parentId);
    }

    fetchData(parentId) {
        //Get current action
        fetch(global.baseAPIUrl + '/locations/' + parentId)
            .then((response) => {
                return response.json();
            })
            .then((locations) => {
                this.setState({
                    locations: locations
                });
            })
    }

    createLocation() {
        var body = {
            name: this.state.currentName,
            address: this.state.currentAddress,
            description: this.state.currentDescription
        };

        //Get current action
        fetch(global.baseAPIUrl + '/locations/' + this.state.parentId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                return response.json();
            })
            .then((locations) => {
                window.location = window.location;
            });
    }

    handleNameChange(event) {
        this.setState({
            currentName: event.target.value,
        });
    }

    handleAddressChange(event) {
        this.setState({
            currentAddress: event.target.value,
        });
    }

    handleDescriptionChange(event) {
        this.setState({
            currentDescription: event.target.value,
        });
    }

    render() {
        var locationTable = (this.state.locations && this.state.locations.length > 0) ?
            <LocationTable locations={this.state.locations}/> : <div/>;

        return (
            <div>
                <div>
                    <h4 className="text-secondary mb-3 mt-4">Locations</h4>
                </div>

                <Grid container spacing={2} className="mb-4 pb-4 pt-4 jumbotron">
                    <Grid item xs={12} sm={12} className="mt-4">
                        <TextField
                            name="name"
                            variant="outlined"
                            required
                            fullWidth
                            id="name"
                            label="Name"
                            defaultValue={this.state.currentImage}
                            onChange={this.handleNameChange}
                            autoFocus
                        />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <TextField
                            name="address"
                            variant="outlined"
                            required
                            fullWidth
                            id="address"
                            label="Address"
                            autoFocus
                            onChange={this.handleAddressChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={5} className="mb-2">
                        <TextField
                            id="description"
                            variant="outlined"
                            required
                            fullWidth
                            label="Description"
                            autoFocus
                            onChange={this.handleDescriptionChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={2} className="text-center">
                        <Button onClick={(e) => {
                            e.stopPropagation();
                            this.createLocation();
                        }} className="bg-primary text-light" style={{height: "100%"}}>
                            <i className="fa fa-plus"></i>
                        </Button>
                    </Grid>
                </Grid>

                {locationTable}
            </div>
        );
    }
}

export default PeopleWrapper;