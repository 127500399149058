import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {ButtonGroup, Table, Modal} from "react-bootstrap";

class ChildrenTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            parentId: props.parentId,
            parent: props.parent,
            children: props.children,
            currentTitle: null,
            currentStart: null,
            currentEnd: null,
            currentAddressTitle: null,
            currentAddress: null,
            currentDescription: null
        }

        this.fetchData = this.fetchData.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleStartChange = this.handleStartChange.bind(this);
        this.handleEndChange= this.handleEndChange.bind(this);
        this.handleAddressTitleChange = this.handleAddressTitleChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    }

    componentDidMount() {
        this.fetchData(this.state.parentId);
    }

    fetchData(parentId) {
        //Get current action
        fetch(global.baseAPIUrl + '/agendas/' + parentId)
            .then((response) => {
                return response.json();
            })
            .then((agendaItems) => {
                this.setState({
                    children: agendaItems
                })
            })
    }

    handleFormValidation() {
        if (!this.state.currentTitle) {
            alert("Something is wrong with the title");
            return false;
        }

        if (!this.state.currentDescription) {
            alert("Something is wrong with the description");
            return false;
        }

        if (!this.state.currentAddressTitle) {
            alert("Something is wrong with the address title");
            return false;
        }

        if (!this.state.currentAddress) {
            alert("Something is wrong with the address");
            return false;
        }

        if (!this.state.currentStart) {
            alert("Something is wrong with the start date");
            return false;
        }

        if (!this.state.currentEnd) {
            alert("Something is wrong with the end date");
            return false;
        }

        return true;
    }

    createAgendaItem(e) {
        var isSafe = this.handleFormValidation();

        if (isSafe) {
            var agendaItem = {
                title: this.state.currentTitle,
                start: this.state.currentStart,
                end: this.state.currentEnd,
                addressTitle: this.state.currentAddressTitle,
                address: this.state.currentAddress,
                description: this.state.currentDescription
            };

            fetch(global.baseAPIUrl + '/agendas/' + this.state.parentId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(agendaItem)
            })
                .then((response) => {
                    return response.json();
                })
                .then((res) => {
                    window.location = window.location;
                });
        }
    }

    deleteAgenda(agendaId) {
        fetch(global.baseAPIUrl + '/agendas/' + agendaId, {
            method: 'DELETE'
        })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                window.location = window.location;
            });
    }

    handleTitleChange(event) {
        this.setState({
            currentTitle: event.target.value,
        });
    }

    handleStartChange(event) {
        this.setState({
            currentStart: event.target.value,
        });
    }

    handleEndChange(event) {
        this.setState({
            currentEnd: event.target.value,
        });
    }

    handleAddressTitleChange(event) {
        this.setState({
            currentAddressTitle: event.target.value,
        });
    }

    handleAddressChange(event) {
        this.setState({
            currentAddress: event.target.value,
        });
    }

    handleDescriptionChange(event) {
        this.setState({
            currentDescription: event.target.value,
        });
    }

    render() {
        if (!this.state.children) {
            this.state.children = [];
        }

        return (
            <div>
                <div>
                    <h4 className="text-secondary mb-3 mt-4">Schedule</h4>
                </div>

                <Grid container spacing={2} className="mb-4 pb-4 jumbotron">
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="title"
                            variant="outlined"
                            required
                            fullWidth
                            id="agendaTitle"
                            label="Title"
                            autoFocus
                            onChange={this.handleTitleChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <TextField
                            id="datetime-local-start"
                            label="Start"
                            type="datetime-local"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{
                                width: "200px"
                            }}
                            onChange={this.handleStartChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <TextField
                            id="datetime-local-end"
                            label="End"
                            type="datetime-local"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{
                                width: "200px"
                            }}
                            onChange={this.handleEndChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12} className="mb-2">
                        <TextField
                            name="description"
                            variant="outlined"
                            required
                            fullWidth
                            id="description"
                            label="Description"
                            autoFocus
                            onChange={this.handleDescriptionChange}/>
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <TextField
                            name="addressTitle"
                            variant="outlined"
                            required
                            fullWidth
                            id="addressTitle"
                            label="Address Title"
                            autoFocus
                            onChange={this.handleAddressTitleChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                        <TextField
                            name="address"
                            variant="outlined"
                            required
                            fullWidth
                            id="address"
                            label="Address"
                            autoFocus
                            onChange={this.handleAddressChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={2} className="text-center">
                        <Button onClick={(e) => {
                            e.stopPropagation();
                            this.createAgendaItem(e);
                        }} className="bg-primary text-light" style={{height: "100%"}}>
                            <i className="fa fa-plus"></i>
                        </Button>
                    </Grid>
                </Grid>


                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>Title</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Address Title</th>
                        <th>Address</th>
                        <th>Description</th>
                        <th></th>
                    </tr>
                    </thead>

                    <tbody>
                    {
                        this.state.children.map((child) =>
                            <tr key={child.Id}>
                                <td>{child.Title}</td>
                                <td>{child.Start}</td>
                                <td>{child.End}</td>
                                <td>{child.AddressTitle}</td>
                                <td>{child.Address}</td>
                                <td>{child.Description}</td>
                                <td style={{width: "4%", padding: "15px"}}>
                                    <ButtonGroup>
                                        <Button onClick={(e) => {
                                            e.stopPropagation();
                                            this.deleteAgenda(child.Id);
                                        }} className="btn-danger square-sm">
                                            <i className="fa fa-trash"></i>
                                        </Button>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default ChildrenTable;