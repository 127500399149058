import React from 'react';
import Button from '@material-ui/core/Button';
import {ButtonGroup, Table, Modal} from "react-bootstrap";
import UpdatePersonModal from "../UpdatePersonModal";

class PeopleTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            people: props.people,
            parent: props.parent,
            children: props.children,
            person: null
        };

        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        console.log("fucker");
        this.setState({
            person: null
        });
    }

    updatePerson(child) {
        console.log(child);
        this.setState({
            person: child
        });
    }

    deletePerson(personId) {
        fetch(global.baseAPIUrl + '/people/' + personId, {
            method: 'DELETE'
        })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                window.location = window.location;
            });
    }

    render() {
        if (!this.state.people) {
            this.state.people = [];
        }

        var updatePerson = this.state.person ?
            <UpdatePersonModal child={this.state.person} close={this.closeModal}/> : <div/>;

        return (
            <div>
                <h4>People</h4>
                {updatePerson}
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>FName</th>
                            <th>LName</th>
                            <th>Position</th>
                            <th>Email</th>
                            <th>Phone</th>
                            <th>Description</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                    {
                        this.state.people.map((child) =>
                            <tr key={child.Id} onClick={(e) => {
                                e.stopPropagation();
                                this.updatePerson(child);
                            }}>
                                <td>
                                    <img className="center-cropped" src={child.Image}/></td>
                                <td>{child.FName}</td>
                                <td>{child.LName}</td>
                                <td>{child.Position}</td>
                                <td>{child.Email}</td>
                                <td>{child.Phone}</td>
                                <td>{child.Description}</td>
                                <td style={{width: "4%", padding: "15px"}} >

                                    <ButtonGroup>
                                        <Button onClick={(e) => {
                                            e.stopPropagation();
                                            this.deletePerson(child.Id);
                                        }} className="btn-danger square-sm">
                                            <i className="fa fa-trash"></i>
                                        </Button>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default PeopleTable;