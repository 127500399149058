import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';

import ChildrenTable from "./ChildrenTable";
import AgendaTable from "./agendas/AgendaTable";
import PeopleWrapper from "./people/PeopleWrapper";
import LocationWrapper from "./locations/LocationWrapper";
import UploadWrapper from "./uploads/UploadWrapper";

import {Paper, Form, Submit} from './DemoModule';

class ParentEdit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false,
            loading: true,
            currentAction: props.action,
            types: props.action.Types,
            originalType: props.action.Type,
            currentTypeId: props.action.TypeId,
            currentImage: props.action.Image,
            currentTitle: props.action.Title,
            currentValues: props.action.Value
        };

        this.saveChanges = this.saveChanges.bind(this);

        this.handleTypeChange = this.handleTypeChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleTitleChange = this.handleTitleChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
    }

    handleTypeChange(event) {
        var curValues = null;

        if (event.target.value === this.state.currentAction.TypeId) {
            curValues = this.state.currentAction.Value;
        }

        this.setState({
            currentTypeId: event.target.value,
            currentValues: curValues
        });
    }

    handleImageChange(event) {
        this.setState({
            currentImage: event.target.value,
        });
    }

    handleTitleChange(event) {
        this.setState({
            currentTitle: event.target.value,
        });
    }

    handleValueChange(event) {
        var values = (this.state.currentValues) ? JSON.parse(this.state.currentValues) : {};
        values[event.target.id] = event.target.value;

        this.setState({
            currentValues: JSON.stringify(values)
        });
    }

    saveChanges(e) {
        var values = (this.state.currentValues) ? this.state.currentValues : null;

        var body = {
            type: this.state.currentTypeId,
            title: this.state.currentTitle,
            image: this.state.currentImage,
            value: values
        };

        fetch(global.baseAPIUrl + '/actions/' + this.state.currentAction.Id, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                window.location = window.location;
            });
    }

    render() {
        var fields = <div/>;
        var nature = 0;

        const self = this;
        this.state.types.forEach(function (type) {
            if (type.Id === self.state.currentTypeId) {
                fields = type.Fields;
                nature = type.Nature;

                return;
            }
        });

        var list = <div/>;

        if (this.state.currentTypeId == 1) {
            list = <ChildrenTable
                parentId={this.state.currentAction.Id}
                children={this.state.currentAction.Children}
                toggle={this.props.toggle}/>;
        } else if (this.state.currentTypeId == 8)  {
            list = <AgendaTable
                parentId={this.state.currentAction.Id}
                children={this.state.currentAction.Children}
                toggle={this.props.toggle}/>;
        } else if (this.state.currentTypeId == 9) {
            list = <PeopleWrapper
                parentId={this.state.currentAction.Id}
                children={this.state.currentAction.Children}
                toggle={this.props.toggle}/>;
        } else if (this.state.currentTypeId == 10) {
            list = <LocationWrapper
                parentId={this.state.currentAction.Id}
                children={this.state.currentAction.Children}
                toggle={this.props.toggle}/>;
        } else if (this.state.currentTypeId == 12) {
            list = <UploadWrapper parentId={this.state.currentAction.Id}/>;
        }

        return (
            <Container component="main">
                <CssBaseline/>
                <div className={Paper}>
                    <Grid container spacing={2}>
                        <Typography className="mb-4" component="h1" variant="h5">
                            Parent
                        </Typography>
                    </Grid>

                    <form className={Form} noValidate>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={8}>
                                <TextField
                                    name="title"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="title"
                                    label="Title"
                                    defaultValue={this.props.action.Title}
                                    autoFocus
                                    onChange={this.handleTitleChange}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormControl
                                    style={{width: "100%"}}>
                                    <InputLabel>Type</InputLabel>
                                    <Select value={this.state.currentTypeId}
                                            onChange={this.handleTypeChange}>
                                        {
                                            this.state.types.map(type =>
                                                <MenuItem value={type.Id} key={type.Id}>
                                                    {type.Type}
                                                </MenuItem>)
                                        }
                                    </Select>
                                    <FormHelperText>{this.state.actionName}</FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={10}>
                                <TextField
                                    name="title"
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="image"
                                    label="Image"
                                    defaultValue={this.state.currentImage}
                                    onChange={this.handleImageChange}
                                    autoFocus
                                />
                            </Grid>

                            <Grid item xs={12} sm={2} className="text-center">
                                <img style={{width: "60px", height: "60px"}} src={this.state.currentImage}/>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                {
                                    (nature !== 0) ? (
                                        fields.map(field =>
                                            <TextField
                                                className="mb-4"
                                                variant="outlined"
                                                key={field.KeyVal}
                                                required
                                                fullWidth
                                                id={field.KeyVal}
                                                label={field.KeyVal}
                                                name={field.KeyVal}
                                                defaultValue={(this.state.currentAction && this.state.currentAction.Value) ? JSON.parse(this.state.currentAction.Value)[field.KeyVal] : ""}
                                                onChange={this.handleValueChange}/>
                                        )
                                    ) : (
                                        list
                                    )
                                }
                            </Grid>
                        </Grid>

                        <Button
                            justify="flex-end"
                            variant="contained"
                            color="primary"
                            onClick={this.saveChanges}
                            className="mt-4 mb-4">
                            Save Changes
                        </Button>
                    </form>
                </div>
            </Container>
        );
    }
}

export default ParentEdit;