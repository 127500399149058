import React from 'react';
import Button from '@material-ui/core/Button';
import {ButtonGroup, Table, Modal} from "react-bootstrap";

class LocationsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            locations: props.locations,
            parent: props.parent,
            children: props.children,
            currentTitle: null,
            currentStart: null,
            currentEnd: null,
            currentAddressTitle: null,
            currentAddress: null,
            currentDescription: null
        };
    }

    deletePerson(personId) {
        fetch(global.baseAPIUrl + '/locations/' + personId, {
            method: 'DELETE'
        })
            .then((response) => {
                return response.json();
            })
            .then((res) => {
                window.location = window.location;
            });
    }

    render() {
        if (!this.state.locations) {
            this.state.locations = [];
        }

        return (
            <div>
                <h4>People</h4>

                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Description</th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                    {
                        this.state.locations.map((child) =>
                            <tr key={child.Id}>
                                <td>{child.Name}</td>
                                <td>{child.Address}</td>
                                <td>{child.Description}</td>
                                <td style={{width: "4%", padding: "15px"}}>
                                    <ButtonGroup>
                                        <Button onClick={(e) => {
                                            e.stopPropagation();
                                            this.deletePerson(child.Id);
                                        }} className="btn-danger square-sm">
                                            <i className="fa fa-trash"></i>
                                        </Button>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        )
                    }
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default LocationsTable;